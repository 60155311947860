'use strict';

var LazyLoad = require('vanilla-lazyload'),
    fp = require('fullpage.js');

var siteGlobal = {
  initReady: function () {
    this.menuInit();
    // this.letterWrapping();

    /* beers */
    this.fullSec();
    this.letterWrapping();
    this.gotoFirst();
  },
  initLoad: function () {
    this.customSelect();
    this.lazyLoad();
    this.linkAll();
    this.inputWrap();

    /* home */
    this.beersCarousel();
    this.brewCarousel();
    this.homeVisualAnim();
    this.brewAnim();

    /* beers */
    this.openingButtons();
    this.animatingBottle();

    /* basei */
    this.baseiCarousel();

    /* brewery */
    this.breweryVisualAnim();
    this.labAnim();

    this.footerAnim();
  },
  initResize: function(){
    this.animatingBottle();
  },
  initResizeEnd: function(){
  },
  initScroll: function(){
    this.headerClass();
  },
  onScroll: function(){
  },
  linkAll: function(){
    $('.linkFrom').each(function() { 
      var elemToLink = $(this).parents('.linkTo');
      var pageLink = $(this).attr('href');
      var linkTarget = (typeof $(this).attr('target')!=='undefined') ?  $(this).attr('target') : '' ;
      if(pageLink !== undefined && pageLink!=='javascript:;') {
        elemToLink.addClass('pointer').click({myLink:pageLink,myTarget:linkTarget},function(e){
          e.preventDefault();
          if(e.data.myTarget==='_blank'){
            window.open(e.data.myLink);
          } else {
            window.location.href = e.data.myLink;
          }
        });
      }
    }); 
  },
  lazyLoad: function(){
    var ll = new LazyLoad({
        elements_selector: '.lazy',
        use_native: true,
        threshold: 600
    })
  },
  headerClass: function(){
    if ($(window).scrollTop() > 0) {
      $('.header').addClass('scrolled');
    } else {
      $('.header').removeClass('scrolled');
    }
  },
  menuInit: function(){
    $('.header__hamburger').on('click', function() {
      $('.header__hamburger, .header__menuWrap').toggleClass('is-active open');
      $('html').toggleClass('menu-open');

      $('.header__menu').on('touchmove', function(e) {
          e.preventDefault();
          e.stopPropagation();
          return false;
      });
 

    })
  },
  letterWrapping: function(){
    $('.homeB__title, .beerSec__title').html(function (i, html) {
        var chars = $.trim(html).split("");
        return '<span>' + chars.join('</span><span>') + '</span>';
    });
  },
  beersCarousel: function(){
    $('.homeBeers__names').slick({
      slidesToShow: 1,
        slidesToScroll: 1,
        speed: 250,
        asNavFor: '.homeBeers__slider',
        fade: true,
        arrows: false,
        dots: false,
        infinite: false,
        touch: false,
        touchMove: false,
    });

    $('.homeBeers__slider').slick({
      slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        prevArrow: '<div class="slick-prev"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 9 39" style="enable-background:new 0 0 9 39;" xml:space="preserve" width="9" height="39"><g><polygon points="9,31.9 5.9,31.9 5.9,0 3.1,0 3.1,31.9 0,31.9 4.5,39 "/></g></svg></div>',
        nextArrow: '<div class="slick-next"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 9 39" style="enable-background:new 0 0 9 39;" xml:space="preserve" width="9" height="39"><g><polygon points="9,31.9 5.9,31.9 5.9,0 3.1,0 3.1,31.9 0,31.9 4.5,39 "/></g></svg></div>',
        fade: false,
        asNavFor: '.homeBeers__names',
        variableWidth: true,
        infinite: false,
        touch: true,
        touchMove: true,
        infinite: false,
        touchThreshold: 1000,
        // autoplay: true,
        // autoplaySpeed: 5000,
    });


    $('.homeBeers__names').on('beforeChange', function(){
      $('.homeB__title').addClass('changingTitle');
      $('.homeB__type').addClass('changingSub');
    });
    $('.homeBeers__names').on('afterChange', function(){
      setTimeout(function(){
        $('.homeB__title').removeClass('changingTitle');
        $('.homeB__type').removeClass('changingSub');
      }, 150)
    });

    $('.homeBeers__slider').on('afterChange', function(){
      // siteGlobal.lazyLoad();
      var new_col = $('.slick-active').data('color');
      console.log(new_col);
      
      $('.home, .homeVisual__block, .homeBeers__block').animate({
            backgroundColor: ''+new_col+''
          }, 300 );

          $('.homeBeers__title, .homeB__title, .homeB__type, .homeB__descTop span').animate({
            color: ''+new_col+''
          }, 200 );

      $('.homeBeers__slider .slick-arrow svg').css('fill',new_col);
      
      $('.slick-current').removeClass('prev');
      $('.slick-current').prevAll().addClass('prev');
      $('.slick-current').prev().removeClass('prev');
    });
  },
  brewCarousel: function(){
    $('.homeBrew__gal, .brewIntro__slider, .brewLab__gal').slick({
      slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        prevArrow: '<div class="slick-prev"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 9 39" style="enable-background:new 0 0 9 39;" xml:space="preserve" width="9" height="39"><g><polygon points="9,31.9 5.9,31.9 5.9,0 3.1,0 3.1,31.9 0,31.9 4.5,39 "/></g></svg></div>',
        nextArrow: '<div class="slick-next"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 9 39" style="enable-background:new 0 0 9 39;" xml:space="preserve" width="9" height="39"><g><polygon points="9,31.9 5.9,31.9 5.9,0 3.1,0 3.1,31.9 0,31.9 4.5,39 "/></g></svg></div>',
        infinite: true,
        touchMove: false,
        autoplay: true,
        autoplaySpeed: 5000,
    });
  },
  homeVisualAnim: function(){
    var controller = new ScrollMagic.Controller(),
      tl = new TimelineMax(),
      claim = $('.homeVisual__title'),
      colBlock = $('.homeVisual__block');

      if ($(window).outerWidth(true) > 991) {
        var tween = tl.to(colBlock, 1.2, {top: '-120', ease: Linear.easeInOut})
              .to(claim, 1.2, {top: '80', ease: Linear.easeInOut});
      } else {
        var tween = tl.to(colBlock, 1.2, {top: '30', ease: Linear.easeInOut});
      }

    var scene = new ScrollMagic.Scene({triggerElement: $('.homeVisual__trig')[0], duration: $(window).outerHeight(true)}).setTween(tween).addTo(controller);
  },
  brewAnim: function(){
    var controller = new ScrollMagic.Controller(),
      tl = new TimelineMax(),
      brewTitle = $('.homeBrew__title'),
      brewTxt = $('.homeBrew__textWrap');

      if ($(window).outerWidth(true) > 767) {
        var tween = tl.to(brewTitle, 1.2, {top: '-30', ease: Linear.easeInOut})
              .to(brewTxt, 1.2, {top: '80', ease: Linear.easeInOut});
      }

    var scene = new ScrollMagic.Scene({triggerElement: $('.homeBrew__trig')[0], duration: $(window).outerHeight(true)}).setTween(tween).addTo(controller);
  },
  openingButtons: function(){
    $('.appBlock__plus').on('click', function(){
      // $('.beerSec__main, .beers__pointsWrap--dots').toggleClass('moving')
      $('.beerSec__main, .beers__pointsWrap--dots').addClass('moving')
      
      if ($(this).hasClass('appBlock__plus--abbinamenti')) {
        $('.beerSec__main, .beers__pointsWrap--dots').addClass('fromLeft')
      }

      if ($(this).hasClass('opened')) {
        $('.beerSec__main, .beers__pointsWrap--dots').removeClass('moving fromLeft')
        $(this).toggleClass('opened')
      } else {
        $('.appBlock__plus, .appBlock').removeClass('opened');
        $(this).toggleClass('opened');
      }
      
      var ref = $(this).data('ref');
      $('[data-block="'+ref+'"]').toggleClass('opened').fadeIn(200);

      if ($(window).outerWidth(true) < 768) {
        if($('.appBlock__plus.opened').length){
          $('.beerSec__btn').fadeOut(0);
        } else {
          setTimeout(function(){
            $('.beerSec__btn').fadeIn(250);
          }, 350)
        }
      }
    })
  },
  animatingBottle: function(){
    var sectionsCount = $('.beerSec--col').length;
    $('.beerSec').each(function(){
      var controller = new ScrollMagic.Controller(),
        tl = new TimelineMax(),
        bottle = $('.beerSec__imageWrap'),
        etichetta = $('.beerSec__etichetta'),
        trig = $(this).find($('.beerSec__trigger'));

        // var tween = tl.to(etichetta, .5, {left: '-='+$('.beerSec__etichetta').outerWidth(true) / (sectionsCount), });
        var tween = tl.to(etichetta, .5, {left: '-='+$('.beerSec__etichetta').width() / (sectionsCount), });

      if ($(window).outerWidth(true) < 767) {
        var scene = new ScrollMagic.Scene({triggerElement: $(trig)[0], duration: 550}).setTween(tween).addTo(controller);
      } else {
        var scene = new ScrollMagic.Scene({triggerElement: $(trig)[0], duration: 1000}).setTween(tween).addTo(controller);
      }
    })
  },
  fullSec: function(){
    var anim = new fp('#fullpage', {
      licenseKey: "28C60078-3E57492A-BE530DB3-C6951945",
      scrollingSpeed: 900,
      continuousVertical: false,
      // anchors: ['section1', 'section2', 'section3', 'section4', 'section5', 'section6', 'section7'],
      onLeave: function(section, origin, destination, direction){
              // var params = {
              //     section: section,
              //     origin: origin,
              //     destination: destination,
              //     direction: direction
              // };
              $('.beers__pointsWrap, .beers__dataWrap').removeClass('active');
              $('.beerSec__main, .beers__pointsWrap--dots').removeClass('moving fromLeft')
              $('.opened').removeClass('opened');

              console.log(destination);
              
              if ($('.beerSec--daina').hasClass('active') && destination == 'down') {
                $('.beerSec__main').addClass('last_slide');
              } else {
                $('.beerSec__main').removeClass('last_slide');
              }
          },
      afterLoad: function(origin, destination, direction){
              var params = {
                  origin: origin,
                  destination: destination,
                  direction: direction
              };
              var current_sec = $(params.destination.item).data('sec-name');
              $('[data-points="'+current_sec+'"], [data-sheet="'+current_sec+'"]').addClass('active');
          }
    });
  },
  gotoFirst: function(){
    $('.footer__top').on('click', function(){
      fullpage_api.moveTo(1);
    })
  },
  baseiCarousel: function(){
    jQuery('.baseiIntro__slider, .baseiSlider__sl').slick({
      slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        prevArrow: '<div class="slick-prev"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 9 39" style="enable-background:new 0 0 9 39;" xml:space="preserve" width="9" height="39"><g><polygon points="9,31.9 5.9,31.9 5.9,0 3.1,0 3.1,31.9 0,31.9 4.5,39 "/></g></svg></div>',
        nextArrow: '<div class="slick-next"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 9 39" style="enable-background:new 0 0 9 39;" xml:space="preserve" width="9" height="39"><g><polygon points="9,31.9 5.9,31.9 5.9,0 3.1,0 3.1,31.9 0,31.9 4.5,39 "/></g></svg></div>',
        infinite: true,
        touchMove: false,
        // autoplay: true,
        autoplaySpeed: 7500,
    });

    jQuery('.baseiIntro__slider, .baseiSlider__sl').on('afterChange', function(){
      siteGlobal.lazyLoad();
    });
    
    jQuery('.baseiSlider__sl').on('afterChange', function(slide, index){
      $('.baseiSlider__currSlide').empty().text(index.currentSlide + 1);
    });
  },
  breweryVisualAnim: function(){
    if ( $(window).outerWidth(true) > 767) {
      var controller = new ScrollMagic.Controller(),
        tl = new TimelineMax(),
        brewSlide = $('.brewIntro .brewIntro__sliderWrap'),
        brewBlack = $('.brewIntro__black'),
        brewTxt = $('.brewIntro__mainText');

        var tween = tl.to(brewSlide, 1.2, {top: '35', ease: Linear.easeInOut}).to(brewBlack, 1.2, {left: '-45', ease: Linear.easeInOut}, "-=1").to(brewTxt, .7, {top: '35', ease: Linear.easeInOut}, "-=1.4");

      var scene = new ScrollMagic.Scene({triggerElement: $('.brewIntro__trig')[0], duration: $(window).outerHeight(true)}).setTween(tween).addTo(controller);
    }
  },
  footerAnim: function(){
    var controller = new ScrollMagic.Controller(),
      tl = new TimelineMax(),
      footBg = $('.footCont__intro');

      var tween = tl.to(footBg, 1.2, {'background-position': '100% 0', ease: Linear.easeInOut});

    var scene = new ScrollMagic.Scene({triggerElement: $('.homeBrew__trig')[0], duration: $(window).outerHeight(true)}).setTween(tween).addTo(controller);
  },
  labAnim: function(){
    var controller = new ScrollMagic.Controller(),
      tl = new TimelineMax(),
      labTit = $('.brewLab .brewLab__title');

      var tween = tl.to(labTit, 1.2, {top: '50', ease: Linear.easeInOut});

    var scene = new ScrollMagic.Scene({triggerElement: $('.brewLab__trig')[0], duration: $(window).outerHeight(true)}).setTween(tween).addTo(controller);
  },
  inputWrap: function(){    
    $('[type="checkbox"]').each(function(){
      $(this).after('<span class="check_wrap"></span>');
    });
  },
  customSelect: function(){
    $('select').each(function(){
        var $this = $(this), numberOfOptions = $(this).children('option').length;
      
        $this.addClass('select-hidden'); 
        $this.wrap('<div class="select"></div>');
        $this.after('<div class="select-styled"></div>');

        var $styledSelect = $this.next('div.select-styled');
        $styledSelect.text($this.children('option').eq(0).text());
      
        var $list = $('<ul />', {
            'class': 'select-options'
        }).insertAfter($styledSelect);
      
        for (var i = 0; i < numberOfOptions; i++) {
            $('<li />', {
                text: $this.children('option').eq(i).text(),
                rel: $this.children('option').eq(i).val()
            }).appendTo($list);
        }
      
        var $listItems = $list.children('li');
      
        $styledSelect.on('click', function(e) {
            e.stopPropagation();
            $(this).toggleClass('active').next('ul.select-options').slideToggle(150);
            $('div.select-styled.active').not(this).each(function(){
                $(this).removeClass('active').next('ul.select-options').hide();
            });
        });
      
        $listItems.on('click', function(e) {
            e.stopPropagation();
            $styledSelect.text($(this).text()).removeClass('active');
            $this.val($(this).attr('rel'));
            $list.hide();
        });
      
        // $(document).on('click', function() {
        //     $styledSelect.removeClass('active');
        //     $list.hide();
        // });

    });
  },
  rebuildAllEvents: function(){
    this.initLoad();
  },
};

global.siteGlobal = siteGlobal;

(function () {

  siteGlobal.initReady();

  $( window ).on('load', function() {
    $('body, #preloader').addClass('loaded');
    siteGlobal.initLoad();
  });

  $(window).resize(function(){
    siteGlobal.initResize();
  });


  if(typeof $(window).resizeend === 'function'){
    $(window).resizeend(function () {
      siteGlobal.initResizeEnd();
    });
  } else {
    $(window).resize(function(){
      siteGlobal.initResizeEnd();
    });
  }
  $(window).scroll(function(){
    siteGlobal.initScroll();
  });

  $(window).on('scroll', function(){
    siteGlobal.onScroll();
  })
}());



